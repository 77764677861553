import React from "react"
import Layout from "../components/Layout"
import { Container, Tab, Nav } from "react-bootstrap"
import '../styles/tab_photos.css'
import FarwellDay from "../components/events/FarwellDay"
import IndependenceDay from "../components/events/IndependenceDay"
import FreshersDay from "../components/events/FreshersDay"
import SendoffDay from "../components/events/SendoffDay"
import Sports from "../components/events/Sports"
import Head from '../components/Head';



function Events() {
    return (
        <div className='overflow-hidden '>
            <Head title="Events"/>
            <Layout>
                <Container fluid className="px-5">
                    
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first" className="tabContainer d-flex justify-content-center">
                        <Nav variant="pills" className="flex-row  campusTabs" >
                            <Nav.Item >
                                <Nav.Link className="tab" eventKey="first" >Farwell Day</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link className="tab" eventKey="second">Independence Day</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link className="tab" eventKey="third">Fresher's Day</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link className="tab" eventKey="fourth">Sendoff Day</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link className="tab" eventKey="fifth">Sports</Nav.Link>
                            </Nav.Item>
                            
                        </Nav>


                        <Tab.Content>
                            <Tab.Pane eventKey="first" className=''>
                                <FarwellDay/>
                            </Tab.Pane>
                            <Tab.Pane eventKey="second" className=''>
                                <IndependenceDay />
                            </Tab.Pane>
                            <Tab.Pane eventKey="third" className=''>
                                <FreshersDay />
                            </Tab.Pane>
                            <Tab.Pane eventKey="fourth" className=''>
                                <SendoffDay />
                            </Tab.Pane>
                            <Tab.Pane eventKey="fifth" className=''>
                                <Sports />
                            </Tab.Pane>
                            
                        </Tab.Content>
                    </Tab.Container>




                </Container>


            </Layout>

            
        </div>

    )
}

export default Events;
