import React from 'react'
import { Container } from 'react-bootstrap'
import { useEffect } from 'react'
import AOS from 'aos';


function SendoffDay() {

    useEffect(() => {
        AOS.init({
          duration : 2000
        });
        AOS.refresh();
    
      }, []);

  return (
    <div className='overflow-hidden '>
        <Container className="px-5">
            <header className="sectionHeader halfBorder mb-5 headerLeftMargin">Sendoff Day</header>

            <div className="gallery d-flex flex-wrap"> 
                <figure data-aos="fade-up-right">
                    <img fluid  src="/events/sendoff/sendoff1.jpg" alt="" />
                </figure>   
                <figure data-aos="fade-up-left">
                    <img fluid  src="/events/sendoff/sendoff2.jpg" alt="" />
                </figure> 
                <figure data-aos="fade-up-right">
                    <img fluid  src="/events/sendoff/sendoff3.jpg" alt="" />
                </figure>   
                <figure data-aos="fade-up-left">
                    <img fluid  src="/events/sendoff/sendoff4.jpg" alt="" />
                </figure>
                <figure data-aos="fade-up-right">
                    <img fluid  src="/events/sendoff/sendoff5.jpg" alt="" />
                </figure>   
                <figure data-aos="fade-up-left">
                    <img fluid  src="/events/sendoff/sendoff5.jpg" alt="" />
                </figure>  
               
            </div>


        </Container>
    </div>
  )
}

export default SendoffDay