import React from 'react'
import { Container } from 'react-bootstrap'
import { useEffect } from 'react'
import AOS from 'aos';


function IndependenceDay() {

    useEffect(() => {
        AOS.init({
          duration : 2000
        });
        AOS.refresh();
    
      }, []);
      
  return (
    <div className='overflow-hidden '>
        <Container className="px-5">
            <header className="sectionHeader halfBorder mb-5 headerLeftMargin">Independence Day</header>

            <div className="gallery d-flex flex-wrap"> 
                <figure data-aos="fade-up-right">
                    <img fluid  src="/events/independence_day/resized-image-Promo (7).jpeg" alt="" />
                </figure>   
                <figure data-aos="fade-up-left">
                    <img fluid  src="/events/independence_day/resized-image-Promo (4).jpeg" alt="" />
                </figure> 
                <figure data-aos="fade-up-right">
                    <img fluid  src="/events/independence_day/resized-image-Promo (5).jpeg" alt="" />
                </figure>   
                <figure data-aos="fade-up-left">
                    <img fluid  src="/events/independence_day/resized-image-Promo (1).jpeg" alt="" />
                </figure> 
                <figure data-aos="fade-up-right">
                    <img fluid  src="/events/independence_day/resized-image-Promo.jpeg" alt="" />
                </figure>   
                <figure data-aos="fade-up-left">
                    <img fluid  src="/events/independence_day/resized-image-Promo (6).jpeg" alt="" />
                </figure> 
                <figure data-aos="fade-up-left">
                    <img fluid  src="/events/independence_day/resized-image-Promo (8).jpeg" alt="" />
                </figure> 
                
            </div>


        </Container>
    </div>
  )
}

export default IndependenceDay