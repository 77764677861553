import React from 'react'
import { Container } from 'react-bootstrap'
import { useEffect } from 'react'
import AOS from 'aos';

function FarwellDay() {

    useEffect(() => {
        AOS.init({
          duration : 2000
        });
        AOS.refresh();
    
      }, []);
      
  return (
    <div className='overflow-hidden '>
        <Container className="px-5 ">
            <header className="sectionHeader halfBorder mb-5 headerLeftMargin">Farwell Day</header>

            <div className="gallery d-flex flex-wrap "> 
            <figure data-aos="fade-up-left">
                    <img fluid  src="/events/farwell_day/DSC_2163.jpeg" alt="" />
                </figure> 
                <figure data-aos="fade-up-right">
                    <img fluid  src="/events/farwell_day/DSC_2162.jpeg" alt="" />
                </figure>   
                
                <figure data-aos="fade-up-right">
                    <img fluid  src="/events/farwell_day/DSC_2164.jpeg" alt="" />
                </figure>   
                <figure data-aos="fade-up-left">
                    <img fluid  src="/events/farwell_day/DSC_2183.jpeg" alt="" />
                </figure> 
               
            </div>


        </Container>
    </div>
  )
}

export default FarwellDay